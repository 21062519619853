<template>
  <div :class="$style.wrapOut">
    <!-- <div :class="$style.breadcrumb">
      <a-breadcrumb separator=">" :class="$style.breadcrumbWrap">
        <a-breadcrumb-item :class="$style.breadcrumbItem" v-for="cur in gameData?.categories">{{cur || '-'}}</a-breadcrumb-item>
      </a-breadcrumb>
    </div> -->
    <div :class="$style.wrap">
      <div :class="$style.gameInfo">
        <div :class="$style.infoLeft">
          <SplideCarousel :images="gameData?.lunbo || []" />
          <!-- <a-carousel
            arrows
            dots-class="slick-dots slick-thumb"
            autoplay
            :autoplaySpeed="3000"
          >
            <template #customPaging="props">
              <a>
                <img :src="getFullImageUrl(`media/${gameData?.lunbo?.[props?.i]}`)" />
              </a>
            </template>
            <div v-for="item in gameData?.lunbo" :class="$style.cardGrid">
              <img :class="$style.cardGridImg" :src="getFullImageUrl(`media/${item}`)" />
            </div>
          </a-carousel> -->
          <div :class="$style.leftOptWrap">
            <div :class="$style.downloadWrap" @click="$openInNewTab('/news/57.html')">
              <DownloadOutlined :class="$style.downloadIcon" />
              <span>下载存档工具</span>
            </div>
            <div :class="$style.leftOpt">
              <div :class="$style.leftOptL">不感兴趣</div>
              <div :class="$style.leftOptD"></div>
              <div :class="$style.leftOptR">报错</div>
            </div>
          </div>
          <div :class="$style.huodongWrap">分享赚10%-70%</div>
        </div>
        <div :class="$style.infoRight">
          <div :class="$style.rightTitle">{{gameData?.gname || '-'}}</div>
          <div :class="$style.rightEtitle">
            <div :class="$style.eTitle">{{gameData?.ename || '-'}}</div>
            <div :class="$style.collect" @click="onClickCollect">{{isCollect ? '取消收藏' : '收藏'}}</div>
          </div>
          <div :class="$style.rightSeries">
            <div :class="$style.series">
              <div :class="$style.seriesTitle">游戏分类</div>
              <div :class="$style.seriesCon">{{gameData?.categories?.join(' ') || '-'}}</div>
            </div>
          </div>
          <div :class="$style.rightSeries">
            <div :class="$style.series">
              <div :class="$style.seriesTitle">游戏平台</div>
              <div :class="$style.seriesCon">{{gameData?.pingtai || '-'}}</div>
            </div>
            <div :class="$style.series">
              <div :class="$style.seriesTitle">游戏等级</div>
              <div :class="$style.seriesCon">{{gameData?.level || '-'}}</div>
            </div>
          </div>
          <div :class="$style.rightVip">
            <div :class="$style.rightVipImg">会 员</div>
            <div :class="$style.rightVipText">可免费畅玩全站3000+款游戏，价值10万+元。</div>
            <div :class="$style.rightVipBtn"  @click="openVipPayment">开通会员</div>
          </div>
          <!-- <div :class="$style.rightDir"></div> -->
          <div :class="$style.rightSkuWrap">
            <div v-for="item in skuData" :class="[$style.rightSku, curSku?.sku_name === item?.sku_name && $style.activeSku]" @click="setCurSku(item)">
              <div :class="[$style.rightPrice, curSku?.sku_name === item?.sku_name && $style.activePrice]">¥{{item?.show_price_info?.use_dkj_price?.toFixed(2) || '-'}}</div>
              <div :class="$style.rightDePrice">已抵扣：{{item?.show_price_info?.use_dkj_amount?.toFixed(2) || '-'}}</div>
              <div :class="[$style.rightSkuName, curSku?.sku_name === item?.sku_name && $style.acticeSkuName]">{{item?.sku_name || '-'}}</div>
            </div>
            <div v-for="item in vipData" :class="[$style.rightSku, curSku?.vip_sku_value === item?.vip_sku_value && $style.activeSku]" @click="setCurSku(item)">
              <div :class="[$style.rightPrice, curSku?.vip_sku_value === item?.vip_sku_value && $style.activePrice]">¥{{item?.use_dkj_price?.toFixed(2) || '-'}}</div>
              <div :class="$style.rightDePrice">已抵扣：{{item?.use_dkj_amount?.toFixed(2) || '-'}}</div>
              <div :class="[$style.rightSkuName, curSku?.vip_sku_value === item?.vip_sku_value && $style.acticeSkuName]">{{item?.vip_sku_value || '-'}}</div>
            </div>
          </div>
          <div :class="$style.rightText">
            <span :class="$style.rightTexto">永久版</span>
            <span :class="$style.rightTextt">可永久无限次租用此正版游戏Steam帐号，无后续费用。</span>
          </div>
          <div :class="$style.rightBtn">
            <a-button
              type="primary"
              :class="$style.rightBtnB"
              :disabled="!is_allow_click_pay"
              @click="onClickBuy"
            >
              {{ is_allow_click_pay ? '立即购买' : '此游戏暂未上架' }}
            </a-button>
            <a-button v-if="gameData?.is_allow_vip_free" :class="$style.rightBtnV" @click="onClickVipBuy">会员免费玩</a-button>
          </div>
          <div :class="$style.rightTip">
            <div>温馨提示：</div>
            <div>1、如果支付失败，抵扣金将5分钟左右返回！</div>
            <div>2、不得使用外挂，仅个人使用，不能进行转租借或者假激活等商业盈利行为！</div>
          </div>
        </div>
      </div>
      <!-- 新游推荐 -->
      <div :class="$style.new">
        <GamesReBanner
          :left-icon="newIcon"
          :left-img="leftImg"
          :right-img="rightImg"
          left-title="新游推荐"
          :game-infos="getGameInfos(newGames)"
          @on-click-more="onClickMoreNew"
        />
      </div>
      <!-- Tab 页面 -->
      <div :class="$style.gameDetail">
        <a-tabs defaultActiveKey="1" :class="$style.tabWrap">
          <a-tab-pane key="1">
            <template #tab>
              <span style="font-size: 20px;">
                游戏介绍
              </span>
            </template>
            <div :class="$style.gameDetailCon" v-html="gameData?.introduction || ''"></div>
          </a-tab-pane>

          <a-tab-pane key="2">
            <!-- <div :class="$style.gameDetailTit">游戏存档</div> -->
            <template #tab>
              <span style="font-size: 20px;">
                游戏存档
              </span>
            </template>
            <div :class="$style.gameDetailCon">存档说明：并不是每个游戏都支持存档移档和替换操作，只支持大部分的情况。</div>
            <div :class="$style.gameDetailCon">存档位置：{{gameData?.web_archives_video_info?.title || '-'}}</div>
            
            <div :class="$style.videoWrap" v-if="gameData?.web_archives_video_info?.video_url">
              <video controls style="width: 100%; height: auto;">
                <source :src="gameData?.web_archives_video_info?.video_url" type="video/mp4">
                您的浏览器不支持视频播放。
              </video>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>

      
      <!-- <div :class="$style.gameDetail">
        <div :class="$style.gameDetailTit">游戏介绍</div>
        <div :class="$style.gameDetailCon" v-html="gameData?.introduction || ''"></div>
      </div> -->

      
    </div>
    <PaymentModal
      :isOpen="isOpen"
      :isOrderOpen="isOrderOpen"
      :curSku="curSku"
      :paymentType="paymentType"
      :orderInfo="orderInfo"
      @setIsOpen="backClosePayment"
      @setIsOrderOpen="(value) => (isOrderOpen = value)"
    />

    <RentAccountDialog
      :isOpen="isRentAccountDialog"
      :pkId="rentPkId"
      :rentType="'vip'"
      :curGame="gameData?.gname"
      @setIsOpen="setIsRentAccountDialog"
      @successCallBack="onRentAccountSuccess"
    />
    <RentalSuccessModal
      :is-open="isRentalSuccessOpen"
      :set-isOpen="(flag) => { isRentalSuccessOpen = flag}"
      :end-time="rentalEndTime"
    />
  </div>
</template>

<script setup>
  import { ref, onMounted, watch, computed } from "vue";
  import { useRoute, useRouter } from 'vue-router'
  import axios from "@/axios";
  import { DownloadOutlined } from '@ant-design/icons-vue';
  import { message } from 'ant-design-vue';


  import { getFullImageUrl, openLink } from "@/util/utils.js";
  import GamesReBanner from "@/views/homeView/components/GamesReBanner.vue";
  import BuyPlanModal from '@components/BuyPlanModal.vue';
  import RentAccountDialog from '@components/SelectAccountModal.vue';
  import RentalSuccessModal from '@components/RentalSuccessModal.vue';
  import SplideCarousel from '@components/splideCarousel.vue';
  import deLLeft from "@assets/deLLeft.png";
  import deLRight from "@assets/deLRight.png";
  import huiyuan from "@assets/huiyuan.png";
  import newIcon from "@assets/newIcon.png";
  import leftImg from "@assets/leftImg.png";
  import rightImg from "@assets/rightImg.png";


  const route = useRoute();
  const router = useRouter();
  const gameData = ref();
  const skuData = ref();
  const is_allow_click_pay = ref(true);
  const vipData = ref();
  const newGames = ref([]);
  const isCollect = ref(false); // 是否收藏

  import PaymentModal from "@/components/BuyPlanModalv3.vue";
  const isOpen = ref(false);
  const isOrderOpen = ref(false);
  const paymentType = ref(0);
  const curSku = ref(null);
  const orderInfo = ref(null);
  const isRentalSuccessOpen = ref(false);
  const rentalEndTime = ref();

  import { useStore } from "vuex";
  const store = useStore();
  const isAuthenticated = computed(() => store.state.auth.isAuthenticated);
  const tmp = ref();
  // SKU 支付
  const openSkuPayment = () => {
    paymentType.value = 0;
    // curSku.value = sku;
    isOpen.value = true;
  };

  const backClosePayment = (value) => {
    tmp.value = Date.now();
    isOpen.value = value;
  }

  // 模拟 VIP 支付
  const openVipPayment = () => {
    if (isAuthenticated.value === false) {
      store.commit('SET_LOGINMODALTYPE', "login");
      store.dispatch("authModal/showLoginModal");
      return;
    }
    paymentType.value = 1;
    curSku.value = null;
    isOpen.value = true;
  };


  // 租号
  const rentPkId = ref();
  const isRentAccountDialog = ref(false);

  const setIsRentAccountDialog = (flag) => {
    isRentAccountDialog.value = flag;
  }

    // 点击会员免费玩
  const onClickVipBuy = async () => {
    if (isAuthenticated.value === false) {
      store.commit('SET_LOGINMODALTYPE', "login");
      store.dispatch("authModal/showLoginModal");
      return;
    }
    const res = await axios.post("web/rentno/vip_rento/", { game_id: route.params?.id });
    if (res?.data?.status === 10000) {
      rentPkId.value = route.params?.id
      isRentAccountDialog.value = true;
    };
    if (res?.data?.status === 10011) {
      openVipPayment()
    };
  }

  const onRentAccountSuccess = (endTime) => {
    // 租号成功，弹框提示弹窗
    rentalEndTime.value = endTime;
    isRentalSuccessOpen.value = true;
  }


  // 切换游戏时，重制选中的curSku
  watch(async () => {
    if (!route.params?.id) return;
    curSku.value = undefined;
  })

  // 获取游戏详情
  watch(async () => {
    if (!route.params?.id) return;
    const res = await axios.post("web/game_detail/", { pk: route.params?.id}).catch(() => {});
    if (res?.data?.status === 10000) {
      gameData.value = res?.data?.data || {};
      isCollect.value = res?.data?.data?.is_collect || false;
      is_allow_click_pay.value = res?.data?.data?.is_allow_click_pay || 0;
      // gameData.value = {
      //   lunbo: ['/gameimg/1230_gNeNusv.jpg', 'gameimg/header_schinese_p2CGooX.jpg', 'gameimg/header_2_jFJlm9n.jpg', 'gameimg/header_XMLgCeP.jpg', 'gameimg/127_0xcZlmk.jpg', 'gameimg/766.jpg']
      // };
    }
  })

  // 获取sku列表
  watch(async () => {
    console.log(tmp.value);
    if (!route.params?.id) return;
    const res = await axios.post("web/skus/", { game_id: route.params?.id}).catch(() => {});
    if (res?.data?.status === 10000) {
      skuData.value = (res?.data?.data?.items || []).slice(0, 1);
      if (skuData.value.length > 0 && !curSku.value) {
      curSku.value = skuData.value[0];
    }
    }
  })

  // 获取会员列表
  watch(async () => {
    console.log(tmp.value, 78878);
    if (!route.params?.id) return;
    const res = await axios.post("web/get_members_buy_plan/", {}).catch(() => {});
    if (res?.data?.status === 10000) {
      vipData.value = (res?.data?.data || []).slice(0, 3);
    }
  });

  // 获取新游推荐
  onMounted(async () => {
    const res = await axios.post("web/games-recommend/", { method: 'new_games_recommendations_ids', limit: 12 }).catch(() => {});
    if (res?.data?.status === 10000) {
      newGames.value = res?.data?.data || [];
    }
  });

  const getGameInfos = (infos) => {
    const arr = [];
    const step = Math.ceil(infos?.length / 4);
    for(let i = 0; i < step; i++) {
      arr.push(infos?.slice(i * 4, (i + 1) * 4))
    }
    return arr;
  }

  // 选择购买方案
  const setCurSku = (cur) => {
    curSku.value = cur;
  }


  // 点击新游推荐更多
  const onClickMoreNew = () => {
    router.push(`/gameStore`);
  }

  // 点击购买
  const onClickBuy = () => {
    if (isAuthenticated.value === false) {
      store.commit('SET_LOGINMODALTYPE', "login");
      store.dispatch("authModal/showLoginModal");
      return;
    }

    if (!curSku?.value) {
      message.warning('请选择购买方式');
      return;
    }
    if (curSku?.value?.is_links) {
      if (!curSku?.value?.link_addr) return;
      window.open(curSku?.value?.link_addr);
      return;
    }

    if (curSku?.value.gname) {
      openSkuPayment()
    } else {
      openVipPayment()
    }
    
  }


  // 收藏 || 取消收藏
  const onClickCollect = async () => {
    if (isAuthenticated.value === false) {
      store.commit('SET_LOGINMODALTYPE', "login");
      store.dispatch("authModal/showLoginModal");
      return;
    }
    if (isCollect.value) {
      const res = await axios.post("web/del_collect_game/", { game_id: route.params?.id }).catch(() => {});
      if (res?.data?.status === 10000) {
        isCollect.value = false;
      }
      return;
    }
    const res = await axios.post("web/collect_game/", { game_id: route.params?.id }).catch(() => {});
    if (res?.data?.status === 10000) {
      isCollect.value = true;
    }
  }

</script>

<style module>
  .wrapOut {
    padding-top: 10px;
  }
  .breadcrumb {
    padding: 0 230px;
    background-color: #F5F5F5;
    margin-bottom: 20px;
  }
  .breadcrumbItem {
    color: #767676 !important;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
  }
  .wrap {
    padding: 10px 230px;
    transform: scale(0.85);
    transform-origin: 50% 0;
    /* margin-bottom: -500px; */
  }
  .gameInfo {
    display: flex;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(103, 103, 103, .23);
    padding: 18px 28px;
  }
  .infoLeft {
    flex-shrink: 0;
    width: 656px;
    height: 305px;
    margin-right: 30px;
  }
  .cardGridImg {
    width: 100%;
    height: 305px;
    border-radius: 8px;
    margin-bottom: 45px;
  }
  .leftOptWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 196px;
    margin-bottom: 50px;
  }
  .downloadWrap {
    display: flex;
    align-items: center;
    color: #3149FF;
    cursor: pointer;
  }
  .downloadWrap span {
    font-size: 20px;
  }
  .downloadIcon {
    font-size: 22px;
    margin-right: 20px;
  }
  .leftOpt {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #888888;
  }
  .leftOptL {
    margin-right: 24px;
    cursor: pointer;
  }
  .leftOptD {
    height: 20px;
    width: 1px;
    margin-right: 24px;
    background-color: #707070;
  }
  .leftOptR {
    cursor: pointer;
  }
  .huodongWrap {
    margin: 0 auto;
    width: 276px;
    height: 51px;
    background-image: url('../../assets/huodong.png');
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 24px;
    line-height: 51px;
    padding-left: 28px;
    color: #fff;
  }
  .infoRight {
    flex-grow: 1;
    width: 0;
  }
  .rightTitle {
    font-size: 34px;
    /* font-weight: bold; */
    color: #2F2F2F;
  }
  .rightEtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 28px;
  }
  .eTitle {
    font-size: 24px;
    color: #8E8E8E;
  }
  .collect {
    flex-shrink: 0;
    font-size: 20px;
    color: #245CD9;
    cursor: pointer;
  }

  .rightSeries {
    margin-top: 36px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .series {
    display: flex;
    align-items: center;
    flex: 1; /* 每个系列占据容器的三分之一 */
    padding: 0 0px; /* 给每个系列增加一点左右内边距 */
  }
  .seriesTitle {
    color: #767676;
    margin-right: 20px;
  }
  .seriesCon {
    color: #000000;
    font-weight: bold;
    white-space: nowrap; /* 防止内容换行 */
  }
  .rightVip {
    margin-top: 36px;
    background-color: rgba(245, 184, 18, .28);
    border-radius: 21px;
    display: flex;
    align-items: center;
    padding-right: 21px;
  }
  .rightVipImg {
    width: 112px;
    height: 42px;
    background-image: url('../../assets/huiyuan.png');
    background-repeat: no-repeat;
    background-size: cover;
    line-height: 42px;
    font-size: 22px;
    color: #FCECBE;
    padding-left: 53px;
    margin-right: 20px;
  }
  .rightVipText {
    font-size: 20px;
    color: #412C0E;
  }
  .rightVipBtn {
    font-size: 20px;
    color: #F57212;
    margin-left: auto;
    cursor: pointer;
  }
  .rightDir {
    margin-top: 30px;
    height: 1px;
    background-color: #E5E5E5;
  }
  .rightSkuWrap {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    /* overflow-x: auto; */
  }
  .rightSku {
    flex: 1;
    flex-shrink: 0;
    /* width: 171px; */
    height: 110px;
    border-radius: 5px;
    border: 1px solid #E2E2E2;
    background-color: #F8F8F8;
    position: relative;
    margin-right: 18px;
    cursor: pointer;
  }
  .rightSku:last-child {
    margin-right: 0;
  }
  .rightPrice {
    font-size: 24px;
    color: #171C61;
    text-align: center;
    margin-top: 15px;
  }
  .rightDePrice {
    font-size: 14px;
    color: #C6C6C6;
    text-align: center;
    margin-top: 8px;
  }
  .rightSkuName {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 32px;
    font-size: 16px;
    text-align: center;
    line-height: 32px;
    margin-top: auto;
    color: #272727;
    background-color: #EAEAEA;
  }
  .activeSku {
    background-color: #FFFBF8;
    border: 2px solid #F56912;
  }
  .activePrice {
    color: #FF1D1D;
  }
  .acticeSkuName {
    color: #FF1D1C;
    background-color: #FDE4D3;
  }
  .rightText {
    margin-top: 15px;
    height: 42px;
    line-height: 42px;
    background-color: #FAFAFA;
    font-size: 18px;
  }
  .rightTexto {
    color: #767676;
    margin-left: 23px;
    margin-right: 44px;
  }
  .rightTextt {
    color: #000000;
  }
  .rightBtn {
    margin-top: 15px;
  }
  .rightBtnB {
    font-size: 25px;
    width: 200px;
    height: 58px;
    background-color: rgba(255, 73, 49, 1);
    border-radius: 5px;
    margin-right: 24px;
  }
  .rightBtnB:hover {
    background-color: rgba(255, 73, 49, .7) !important;
  }
  .rightBtnV {
    font-size: 25px;
    width: 200px;
    height: 58px;
    border-radius: 5px;
    border: 1px solid rgba(255, 168, 49, 1);
    color: rgba(255, 144, 49, 1);
  }
  .rightBtnV:hover {
    border: 1px solid rgba(255, 168, 49, .7) !important;
    color: rgba(255, 144, 49, .7) !important;
  }
  .rightTip {
    margin-top: 21px;
    font-size: 16px;
    color: #5A5A5A;
  }
  .rightTip div {
    line-height: 30px;
  }
  .new {
    margin-top: 27px;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(103, 103, 103, .23);
    padding: 16px;
  }
  .gameDetail {
    margin-top: 35px;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(103, 103, 103, .23);
    padding: 16px;
    /* min-height: 1000px; */
  }
  .gameDetailTit {
    width: 152px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    font-size: 24px;
    color: #ffffff;
    background-color: #3149FF;
    margin-bottom: 31px;
  }
  .gameDetailCon {
    font-size: 22px;
  }
  .custom-tab-title {
    font-size: 30px;
  }
  .videoWrap {
    margin-top: 50px;
  }
  .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 90px;
  }
</style>