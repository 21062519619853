<!-- 我的游戏右侧展示 -->
<template>
  <div :class="$style.contentRight">
    <div :class="$style.contentRightInner">
      <!-- <div :class="$style.contentRightTip">
        <img :class="$style.contentRightTipI" :src="trumpetIcon" alt="" />
        <div :class="$style.contentRightTipT">招募平台推广员，边聊天边赚钱！</div>
      </div> -->
      <div :class="$style.contentRightTG">
        <div :class="$style.contentRightTGT">推广租号官家，游戏免费玩，天天都能赚到钱</div>
        <div :class="[$style.contentRightTGC, $style.contentRightTGF]">
          <div>会员提成比例：{{ kfInfo?.invitation_info?.radio || '-' }}</div>
          <div :class="$style.contentRightTGB" @click="onPromote">我要提升</div>
        </div>
        <div :class="$style.contentRightTGC">我的邀请码：{{ kfInfo?.invitation_info?.code || '-' }}</div>
        <div :class="[$style.contentRightTGC, $style.contentRightTGF]">
          <div style="display: flex; align-items: center">
            <div style="flex-shrink: 0;">我的推广链接：</div>
            <div :class="$style.contentRightTGCH">{{ fullPromotionLink }}</div> <!-- 显示完整的推广链接 -->
          </div>
          <div :class="$style.contentRightTGB" @click="onCopyTg(fullPromotionLink)">复制链接</div> <!-- 复制完整链接 -->
        </div>
        <div :class="$style.contentRightTGC">我的联系方式：{{ kfInfo?.invitation_info?.wechat_code || '-' }}</div>
      </div>
      <div :class="$style.rightVip">
        <div :class="$style.rightVipImg">会 员</div>
        <div :class="$style.rightVipText">可免费畅玩全站3000+款游戏，价值10万+元。</div>
        <div :class="$style.rightVipBtn" @click="openVipPayment">开通会员</div>
      </div>
      <div :class="$style.contentIWrap">
        <div :class="$style.contentI">
          <div :class="$style.contentIT">会员</div>
          <div :class="$style.contentIP">原价</div>
          <div :class="$style.contentID">可抵扣</div>
          <div :class="$style.contentIF">最终权益</div>
        </div>
        <div v-for="item in vipData" :key="item.vip_sku_value" :class="$style.contentI">
          <div :class="$style.contentITit">{{ item?.vip_sku_value || '-' }}</div>
          <div :class="$style.contentIPrice">¥{{ item?.price?.toFixed(2) || '-' }}</div>
          <div :class="$style.contentIDe">¥{{ item?.use_dkj_amount?.toFixed(2) || '-' }}</div>
          <div :class="$style.contentIFP">
            <div :class="$style.contentIFPT">¥{{ item?.use_dkj_price?.toFixed(2) || '-' }}</div>
            <div :class="$style.contentIFPB">立省¥{{ item?.use_dkj_amount?.toFixed(2) || '-' }}</div>
          </div>
        </div>
      </div>
    </div>
    <BuyPlanModal :isOpen="isBuyOpen" @setIsOpen="setIsBuyOpen" />
    <PaymentModal
      :isOpen="isOpen"
      :isOrderOpen="isOrderOpen"
      :curSku="curSku"
      :paymentType="paymentType"
      :orderInfo="orderInfo"
      @setIsOpen="(value) => (isOpen = value)"
      @setIsOrderOpen="(value) => (isOrderOpen = value)"
    />
  </div>
</template>

<script setup>
  import { ref, onMounted, h, useCssModule, computed } from "vue";
  import axios from "@/axios";
  import { message, Modal } from 'ant-design-vue';
  import useClipboard from 'vue-clipboard3';

  import BuyPlanModal from '@components/BuyPlanModal.vue';
  import trumpetIcon from "@assets/trumpetIcon.png";
  import PaymentModal from "@/components/BuyPlanModalv3.vue";
  const isOpen = ref(false);
  const isOrderOpen = ref(false);
  const paymentType = ref(0);
  const curSku = ref(null);
  const orderInfo = ref(null);
  import { useStore } from "vuex";
  const store = useStore();
  const isAuthenticated = computed(() => store.state.auth.isAuthenticated);


  // 模拟 VIP 支付
  const openVipPayment = () => {
    if (isAuthenticated.value === false) {
      store.commit('SET_LOGINMODALTYPE', "login");
      store.dispatch("authModal/showLoginModal");
      return;
    }
    paymentType.value = 1;
    curSku.value = null;
    isOpen.value = true;
  };

  // 获取当前推广链接的完整 URL
  const fullPromotionLink = computed(() => {
    const relativeUrl = kfInfo.value?.invitation_info?.url || '-';
    return relativeUrl.startsWith('http') ? relativeUrl : `${window.location.origin}${relativeUrl}`;
  });

  const { toClipboard } = useClipboard();
  const styles = useCssModule();
  const kfInfo = ref();
  const isBuyOpen = ref(false);
  const vipData = ref();
  const setIsBuyOpen = (flag) => {
    isBuyOpen.value = flag;
  }

  onMounted(async () => {
    const res = await axios.post("web/api/support/details").catch(() => {});
    if (res?.data?.status === 10000) {
      kfInfo.value = res?.data?.data || {};
    }
  });

  // 获取会员信息
  onMounted(async () => {
    const res = await axios.post("web/get_members_buy_plan/", {});
    if (res?.data?.status === 10000) {
      vipData.value = (res?.data?.data || []).slice(0, 3);
    }
  });

  // 复制推广链接
  const onCopyTg = async (text) => {
    if (!text) return;
    try {
      await toClipboard(text);
      message.success('复制成功');
    } catch (e) {
      console.error(e)
    }
  }

  const onPromote = async () => {
    await axios.post("web/news_res_detail/", {
      pk_enum: "我要提升"
    });
  }

</script>

<style module>
  .contentRight {
    flex-shrink: 0;
    flex-grow: 1;
  }
  .contentRightInner {
    width: calc(100% - 20px);
    padding-right: 20px;
  }
  .contentRightTip {
    display: flex;
    align-items: center;
    margin-bottom: 21px;
  }
  .contentRightTipI {
    width: 25px;
    margin-right: 23px;
  }
  .contentRightTipT {
    font-size: 21px;
    color: #FF3B3B;
  }
  .contentRightTG {
    width: 100%;
    height: 389px;
    background-image: url('../assets/tgBgc.png');
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 25px;
    color: #fff;
    margin-bottom: 21px;
    padding: 48px 0 0 58px;
  }
  .contentRightTGT {
    font-size: 36px;
    margin-bottom: 30px;
  }
  .contentRightTGC {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 21px;
    height: 42px;
    line-height: 42px;
  }
  .contentRightTGF {
    display: flex;
    align-items: center;
  }
  .contentRightTGB {
    width: 162px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    border-radius: 42px;
    background-color: #fff;
    color: #FF3E3E;
    font-size: 23px;
    margin-left: 74px;
    cursor: pointer;
    user-select: none;
  }
  .contentRightTGCH {
    word-break: break-all;
    max-width: 500px;
  }
  .tsTitle {
    font-size: 20px;
    font-weight: bold;
  }
  .rightVip {
    background-color: rgba(245, 184, 18, .28);
    border-radius: 21px;
    display: flex;
    align-items: center;
    padding-right: 21px;
    margin-bottom: 21px;
  }
  .rightVipImg {
    width: 112px;
    height: 42px;
    background-image: url('../assets/huiyuan.png');
    background-repeat: no-repeat;
    background-size: cover;
    line-height: 42px;
    font-size: 22px;
    color: #FCECBE;
    padding-left: 53px;
    margin-right: 20px;
  }
  .rightVipText {
    font-size: 20px;
    color: #412C0E;
  }
  .rightVipBtn {
    font-size: 20px;
    color: #F57212;
    margin-left: auto;
    cursor: pointer;
  }
  .contentIWrap {
    display: flex;
    /* margin-bottom: 40px; */
    justify-content: center;
  }
  .contentI {
    border: 1px solid rgba(180, 180, 180, .2);
    width: 270px;
  }
  .contentITit {
    background-color: #FAF4E2;
    color: #1A1A1A;
    font-size: 21px;
    font-weight: bold;
    height: 52px;
    line-height: 52px;
    text-align: center;
  }
  .contentIT {
    background-color: #FAF4E2;
    font-size: 21px;
    color: #1A1A1A;
    font-weight: bold;
    height: 52px;
    line-height: 52px;
    padding-left: 40px;
  }
  .contentIPrice {
    background-color: #FAFAFA;
    color: #646464;
    font-size: 24px;
    height: 76px;
    line-height: 76px;
    text-align: center;
  }
  .contentIP {
    background-color: #FAFAFA;
    color: #1A1A1A;
    font-size: 21px;
    font-weight: bold;
    height: 76px;
    line-height: 76px;
    padding-left: 40px;
  }
  .contentIDe {
    color: #F5B812;
    font-size: 24px;
    height: 52px;
    line-height: 52px;
    text-align: center;
  }
  .contentID {
    color: #1A1A1A;
    font-size: 21px;
    font-weight: bold;
    height: 52px;
    line-height: 52px;
    padding-left: 40px;
  }
  .contentIFP {
    background-color: #FAFAFA;
    padding: 18px 0 15px 0;
  }
  .contentIF {
    background-color: #FAFAFA;
    color: #1A1A1A;
    font-size: 21px;
    font-weight: bold;
    height: 91px;
    line-height: 91px;
    padding-left: 40px;
  }
  .contentIFPT {
    font-size: 29px;
    color: #FF2727;
    text-align: center;
    margin-bottom: 6px;
  }
  .contentIFPB {
    font-size: 16px;
    color: #888888;
    text-align: center;
    text-decoration: line-through;
  }
</style>
