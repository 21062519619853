// store/index.js
import { createStore } from 'vuex';
import auth from './auth';
import authModal from './auth_modal';
import user from './user';
import realnameVerificationModal from './realname_verification_modal';
import gobalMessageModal from './gobal_message_modal';

const store = createStore({
  state: {
    navSelectedKeys: ['/'],  // 根级别的导航选中项
    searchGname: '', // 游戏搜索
    searchGnameInput: '', // 游戏搜索input value
    loginModalType: 'login',
  },
  getters: {
    getNavSelectedKeys: state => state.navSelectedKeys,
    getSearchGname: state => state.searchGname,
  },
  mutations: {
    SET_SELECTEDKEYS(state, keys) {
      state.navSelectedKeys = keys;
    },
    SET_SEARCHGNAME(state, ganme) {
      state.searchGname = ganme;
    },
    SET_SEARCHGNAMEINPUT(state, ganme) {
      state.searchGnameInput = ganme;
    },
    SET_LOGINMODALTYPE(state, type) {
      state.loginModalType = type;
    },
  },
  actions: {
    setNavSelectedKeys({ commit }, keys) {
      commit('SET_SELECTEDKEYS', keys);
    },
    setSearchGname({ commit }, ganme) {
      commit('SET_SEARCHGNAME', ganme);
    },
  },
  modules: {
    auth,  // 认证相关
    authModal,  // 认证弹窗
    user,  // 用户信息相关
    realnameVerificationModal,  // 实名认证弹窗
    gobalMessageModal,
  }
});

export default store;
