<template>
  <div :class="$style.wrap">
    <div :class="$style.wrapTop">
      <!-- 平台筛选 -->
      <div :class="$style.gameList">
        <div
          :class="[$style.gameListI, $style.gameListIActive]"
          @click="onChangePlatform(null)"
        >
          所有游戏
        </div>
        <div
          v-for="platform in platforms"
          :key="platform.id"
          :class="[
            $style.gameListI,
            searchValue.platform === platform.id && $style.gameListIActive,
          ]"
          @click="onChangePlatform(platform.id)"
        >
          <div>{{ platform.name }}</div>
        </div>
      </div>
      <!-- 游戏小方块列表 -->
      <div :class="$style.gameCurList">
        <div
          :class="[
            $style.gameCurListL,
            gameEnums?.length === 16 && $style.gameCurListLm,
          ]"
        >
          <div
            v-for="item of gameEnums"
            :class="[
              $style.gameCurListI,
              gameEnums?.length === 16 && $style.gameCurListIm,
              curPageGameId == item?.game_id && $style.gameCurListIActive,
            ]"
            @click="onChangeCurPageGame(item?.game_id)"
          >
            <div
              :class="[
                $style.gameCurListII,
                curPageGameId == item?.game_id && $style.gameCurListIIActive,
              ]"
            >
              <Image
                :class="$style.gameCurListIII"
                :src="getFullImageUrl(item?.game_icon_img)"
                :preview="false"
              />
            </div>
            <a-tooltip>
              <template #title>{{ item?.game_name || "-" }}</template>
              <div :class="$style.gameCurListIT">
                {{ item?.game_name || "-" }}
              </div>
            </a-tooltip>
          </div>
        </div>
        <div
          :class="$style.gameCurListIAll"
          style="user-select: none"
          @click="
            () => {
              isShowAllGameList = !isShowAllGameList;
            }
          "
        >
          <div
            :class="$style.gameCurListII"
            style="background-color: #237aff; border: none"
          >
            <img :class="$style.allGameImg" :src="allGameImg" alt="" />
          </div>
          <div :class="$style.gameCurListIT">全部游戏</div>
        </div>
      </div>
      <div v-show="isShowAllGameList">
        <div :class="$style.gameAllList">
          <div
            v-for="item of letters"
            :class="[
              $style.gameAllListI,
              curLetter === item && $style.gameAllListIActive,
            ]"
            @click="onChangeLetter(item)"
          >
            {{ item }}
          </div>
        </div>
        <div :class="$style.filterTopGameW">
          <div
            v-for="item of filterTopGame"
            :class="$style.filterTopGameWI"
            @click="
              () => {
                onChangeCurPageGame(item?.game_id);
                isShowAllGameList = false;
                // curLetter = '';
                filterTopGame = [];
              }
            "
          >
            {{ item?.game_name || "-" }}
          </div>
        </div>
      </div>
    </div>

    <!-- 排序筛选 -->
    <div :class="$style.sortType">
      <div
        :class="[
          $style.sortTypeI,
          searchValue.sort === 'default' && $style.sortTypeIActive,
        ]"
        @click="onChangeSort('default')"
      >
        <div>默认</div>
        <img :class="$style.sortTypeII" :src="getSortIcon('default')" alt="" />
      </div>
      <div
        :class="[
          $style.sortTypeI,
          searchValue.sort === 'new' && $style.sortTypeIActive,
        ]"
        @click="onChangeSort('new')"
      >
        <div>最新</div>
        <img :class="$style.sortTypeII" :src="getSortIcon('new')" alt="" />
      </div>
      <div
        :class="[
          $style.sortTypeI,
          searchValue.sort === 'sale' && $style.sortTypeIActive,
        ]"
        @click="onChangeSort('sale')"
      >
        <div>销量</div>
        <img :class="$style.sortTypeII" :src="getSortIcon('sale')" alt="" />
      </div>
    </div>
    <div :class="$style.contentTit" v-show="!!searchGname">
      游戏搜索方法：《我被美女包围了》，搜索“美女”、“包围”，都可以找到该游戏,如果没有找到您想要的游戏，请联系客服。
    </div>
    <div :class="$style.gameRes">
      <div :class="$style.gameResLeft">
        <div
          v-for="item in games"
          :key="item.id"
          :class="$style.gameResItem"
          @click="
            () => {
              onClickGame(item?.id);
            }
          "
        >
          <!-- zuhaowan游戏列表 -->
          <div :class="$style.gameResItemIn">
            <img :class="$style.gameResItemInIO" :src="item?.imgurl" alt="" />
            <div :class="$style.gameResItemInfoO">
              <a-tooltip>
                <template #title>{{ item.pn || "-" }}</template>
                <div :class="$style.gameResItemInfoGO">
                  {{ item.pn || "-" }}
                </div>
              </a-tooltip>
              <div :class="$style.gameResItemOtherA">
                <div :class="$style.gameResItemOtherAa">
                  全区有号/免押金/可排位
                </div>
                <div :class="$style.gameResItemOtherAb">精选低价好号</div>
                <div :class="$style.gameResItemOtherAc">一折起</div>
              </div>

              <div :class="$style.gameResItemOtherB">
                <div :class="$style.gameResItemOtherBI">租3送1</div>
                <div :class="$style.gameResItemOtherBI">租6送2</div>
                <div :class="$style.gameResItemOtherBI">租8送3</div>
                <div :class="$style.gameResItemOtherBI">到时不下线</div>
              </div>
            </div>

            <div :class="$style.gameResItemInPw">
              <div :class="$style.gameResItemInPrice">
                <div :class="$style.gameResItemInPriceI">小时版</div>
                <div :class="$style.gameResItemInPricePO">
                  ¥
                  {{ item?.pmoney }}
                  <span :class="$style.gameResItemInPricePDO"> 元起 </span>
                </div>
              </div>
              <div :class="$style.gameResItemInPriceBzhw">
                {{ item?.need_rent_hour }}小时起租
              </div>

              <div :class="$style.gameResItemInP">
                {{ item.platform }}
              </div>
            </div>
            <div :class="$style.gameResItemInBtnW">
              <a-button
                :class="[$style.gameResItemInBtn, $style.gameResItemInBtnS]"
                type="primary"
                @click="
                  (e) => {
                    // e.stopPropagation();
                    // onClickGame(item);
                  }
                "
                >立即查看</a-button
              >
            </div>
          </div>
        </div>

        <!-- 分页 -->
        <a-pagination
          :current="searchValue.current_page"
          :pageSize="searchValue.page_size"
          :total="total"
          @change="onPageChange"
          @showSizeChange="onPageSizeChange"
        />
      </div>

      <!-- 筛选条件 -->
      <div :class="['gameResRight', $style.gameResRight]">
        <a-collapse
          v-model:activeKey="activeKey"
          expand-icon-position="end"
          :bordered="false"
        >
          <a-collapse-panel key="1" style="color: #237aff">
            <template #header>
              <div style="color: #237aff">当前游戏</div>
            </template>
            <a-select
              :value="curPageGameId"
              :bordered="false"
              :class="$style.gameResRightSelect"
              show-search
              placeholder="选择游戏"
              :options="gameOptions"
              @select="handleSelectGame"
            />
          </a-collapse-panel>
          <a-collapse-panel key="2">
            <template #header>
              <div style="color: #237aff">游戏区服</div>
            </template>
            <a-select
              v-model:value="searchValue.server_id"
              :bordered="false"
              :class="$style.gameResRightSelect"
              show-search
              placeholder="选择区服"
              :options="gameServerOptions"
            />
          </a-collapse-panel>
          <a-collapse-panel key="3">
            <template #header>
              <div style="color: #237aff">游戏价格</div>
            </template>
            <div :class="$style.gameResRightPrice">
              <a-input-number
                v-model:value="searchValue.startPrice"
                prefix="￥"
                placeholder="最低价"
                style="width: 45%"
                :min="0"
              />
              ~
              <a-input-number
                v-model:value="searchValue.endPrice"
                prefix="￥"
                placeholder="最高价"
                style="width: 45%"
                :min="0"
              />
            </div>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, watch, computed, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import axios from "@/axios";
import Image from "@components/Image.vue";
import { getFullImageUrl } from "@/util/utils.js";
import pinyinUtil from "@/util/pinyinUtil";

// 图标
import downSIcon from "@assets/downSIcon.png";
import upSIcon from "@assets/upSIcon.png";
import allGameImg from "@assets/allGameImg.png";

const activeKey = ref(["1", "2", "3", "4"]);
const gameProvider = {
  zuhaowan: "zuhaowan",
  zuhaoguanjia: "zuhaoguanjia",
};

const store = useStore();
const searchGname = computed(() => store.state.searchGname);
const letters = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "W",
  "X",
  "Y",
  "Z",
];

// 响应式数据
const router = useRouter();
const route = useRoute();
const games = ref([]);
const gameEnums = ref([]);
const gameAllEnums = ref([]);
const curPageGameId = ref(route.params?.id || undefined);
const isShowAllGameList = ref(false);
const platforms = ref([]);
const total = ref(0);
const curLetter = ref("A");
const filterTopGame = ref([]);
const gameOptions = ref([]);
const gameServerOptions = ref([]);

const searchValue = reactive({
  game_id: route.params?.id || undefined,
  server_id: undefined,
  startPrice: undefined,
  endPrice: undefined,
  current_page: 1,
  page_size: 50,
  sort: "default",
});

// 获取游戏区服
const fetchGameServer = async (gameId) => {
  if (!gameId) return;
  const res = await axios
    .post("web/gys/zhw_game_zone_server/", { game_id: gameId })
    .catch(() => {});
  if (res?.data?.status === 10000) {
    const gameZones = res?.data?.data?.zone_list || [];
    gameServerOptions.value = res?.data?.data?.server_list?.map?.((item) => {
      let label = "";
      const curZone = gameZones?.find((cur) => cur?.ID === item?.ParentID);
      if (curZone) {
        label = `${curZone?.ServerName ? `${curZone?.ServerName} - ` : ""}${
          item?.ServerName ? item?.ServerName : ""
        }`;
      } else {
        label = item?.ServerName;
      }
      return {
        label,
        value: item?.ID,
      };
    });
  }
};

const handleSelectGame = (val) => {
  curPageGameId.value = val;
  searchValue.server_id = undefined;
};

watchEffect(() => {
  if (!searchValue.game_id) return;
  fetchGameServer(searchValue.game_id);
});

// 获取游戏枚举数据
const fetchGameEnum = async () => {
  const _res = await axios.post("web/get_game_show_rule_list/", {
    type: "zhw_show_ids",
  });
  gameEnums.value = _res?.data?.data?.splice(0, 16) || [];

  const res = await axios.post("web/get_game_enum/", { provider: 1 });
  gameAllEnums.value = res?.data?.data || [];
  gameOptions.value =
    res?.data?.data?.map?.((item) => ({
      label: item?.game_name,
      value: item?.game_id ? `${item?.game_id}` : "",
    })) || [];
};

// 获取枚举数据
const fetchPlatforms = async () => {
  const res = await axios.post("web/get_game_platforms/");
  platforms.value = res?.data?.data || [];
};

// 获取游戏列表
const fetchGames = async () => {
  const { startPrice, endPrice, ...otherSearch } = searchValue;
  const res = await axios.post("web/gys/zhw_search_account/", {
    ...otherSearch,
    xhw_price: `${startPrice ?? ""},${endPrice ?? ""}`,
    sort1: searchValue.sort1 || "-weight", // 默认值为 `-weight`
    sort2: searchValue.sort2 || "-created", // 默认值为 `-created`
    sort3: searchValue.sort3 || "-sale_count", // 默认值为 `-sale_count`
  });
  games.value = res?.data?.data?.list || [];
  total.value = res?.data?.data?.total || 0;
};

// 页面加载时调用
onMounted(() => {
  searchValue.sort1 = "-weight"; // 默认排序为权重降序
  searchValue.sort2 = "-created"; // 默认排序为创建时间降序
  searchValue.sort3 = "-sale_count"; // 默认排序为销量降序
  fetchGameEnum();
  fetchPlatforms();
  fetchGames();
});

// 监听搜索条件变化
watch(searchValue, fetchGames);

watch(searchGname, () => {
  searchGname && (searchValue.q = searchGname);
});

watch(curPageGameId, () => {
  curPageGameId && (searchValue.game_id = curPageGameId);
});

// 修改筛选条件
const onChangePlatform = () => {
  router.push("/gameStore");
};

const onChangeCurPageGame = (gameId) => {
  curPageGameId.value = gameId ? `${gameId}` : "";
};

const onChangeSort = (sort) => {
  // 根据点击的排序类型更新对应的排序字段
  if (sort === "default") {
    searchValue.sort1 = searchValue.sort1 === "-weight" ? "weight" : "-weight";
  } else if (sort === "new") {
    searchValue.sort2 =
      searchValue.sort2 === "-created" ? "created" : "-created";
  } else if (sort === "sale") {
    searchValue.sort3 =
      searchValue.sort3 === "-sale_count" ? "sale_count" : "-sale_count";
  }

  // 更新当前选中的排序类型
  searchValue.sort = sort;
};

const getSortIcon = (sort) => {
  if (sort === "default") {
    return searchValue.sort1 === "-weight" ? downSIcon : upSIcon;
  } else if (sort === "new") {
    return searchValue.sort2 === "-created" ? downSIcon : upSIcon;
  } else if (sort === "sale") {
    return searchValue.sort3 === "-sale_count" ? downSIcon : upSIcon;
  }
  return downSIcon; // 默认降序图标
};

const onPageChange = (page) => {
  searchValue.current_page = page;
};

const onPageSizeChange = (current, size) => {
  searchValue.current_page = current;
  searchValue.page_size = size;
};

const onClickGame = (accountId) => {
  window.open(
    `/zhwAccountDetail/${accountId}?gameId=${route.params?.id}`,
    "_blank"
  );
};

watchEffect(() => {
  filterTopGame.value = gameAllEnums.value?.filter?.((item) => {
    const firstChar = item?.game_name?.charAt?.(0);
    if (/^[a - zA - Z]+$/.test(firstChar)) {
      // 英文
      return firstChar
        ?.toLowerCase()
        ?.startsWith?.(curLetter.value?.toLowerCase?.());
    } else {
      // 汉字
      const firstCharPY = pinyinUtil?.getFirstLetter?.(firstChar);
      return firstCharPY
        ?.toLowerCase()
        ?.startsWith?.(curLetter.value?.toLowerCase?.());
    }
  });
});
const onChangeLetter = (cur) => {
  curLetter.value = cur;
};
</script>

<style module>
.wrap {
  padding: 13px 230px 0;
}

.wrapTop {
  padding: 16px 16px 20px;
  box-shadow: 0 0 10px 0 rgba(103, 103, 103, 0.23);
  border-radius: 8px;
}

.gameList {
  display: flex;
  margin-bottom: 13px;
}

.gameAllList {
  padding: 16px 0;
  display: flex;
}
.gameAllListI {
  margin-right: 8px;
  line-height: 28px;
  font-size: 14px;
  padding: 0 7px;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 3px;
}
.gameAllListIActive {
  background-color: #237aff;
  color: #ffffff;
}
.filterTopGameW {
  display: flex;
  flex-wrap: wrap;
}
.filterTopGameWI {
  font-size: 14px;
  margin-right: 30px;
  margin-bottom: 10px;
  cursor: pointer;
}
.filterTopGameWI:hover {
  color: #237aff;
}

.gameListI {
  width: 96px;
  height: 36px;
  font-size: 16px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
  cursor: pointer;
  color: #393939;
}

.gameListIActive {
  background-color: #237aff;
  color: #fff;
}

.gameListII {
  width: 33px;
  height: 33px;
  margin-right: 18px;
}

.gameListIP {
  width: 56px;
  height: 56px;
  margin-right: 9px;
}

.gameListIYB {
  width: 42px;
  height: 40px;
  margin-right: 12px;
}

.gameListIEP {
  width: 36px;
  height: 41px;
  margin-right: 19px;
}

.gameCurList {
  display: flex;
  justify-content: space-between;
}
.gameCurListL {
  flex-flow: 1;
  display: flex;
}
.gameCurListI {
  width: 70px;
  cursor: pointer;
  margin-right: 15px;
  font-size: 13px;
  color: #2b2b2b;
}
.gameCurListLm {
  justify-content: space-between;
}
.gameCurListIm {
  margin-right: 0 !important;
}
.gameCurListIAll {
  width: 70px;
  margin-left: 15px;
  font-size: 13px;
  color: #2b2b2b;
}

.gameCurListII {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #e3e3e3;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 5px;
}

.gameCurListIIActive {
  border: 2px solid #237aff;
}

.gameCurListIActive {
  color: #237aff;
}

.gameCurListIII {
  width: 100% !important; /* 固定宽度为 58px */
  height: auto; /* 高度自动调整，保持比例 */
  object-fit: contain; /* 确保图片完整显示，保持比例，可能会有空白区域 */
  display: block; /* 避免图片被当作行内元素，影响布局 */
}

.gameCurListIT {
  width: 70px;
  text-align: center;
  line-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.allGameImg {
  width: 36px;
  height: 36px;
}

.sortType {
  display: flex;
  align-items: center;
  margin: 7px 15px;
}

.sortTypeI {
  font-size: 16px;
  font-weight: 400;
  color: #393939;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  margin-right: 65px;
  cursor: pointer;
}

.sortTypeIActice {
  background-color: #f5f7fa;
  /* color: #fff; */
}

.sortTypeII {
  width: 13px;
  height: 8px;
  margin-left: 15px;
}

.topPic {
  display: flex;
  margin-bottom: 25px;
}

.topPicI {
  flex-shrink: 0;
  margin-right: 35px;
  cursor: pointer;
}

.topPicII {
  width: 264px;
  height: 123px;
  border-radius: 5px;
  margin-bottom: 9px;
}

.topPicGN {
  font-size: 13px;
  line-height: 19px;
  font-weight: bold;
  color: #363636;
  margin-bottom: 2px;
}

.topPicGE {
  line-height: 10px;
  font-size: 7px;
  color: #363636;
  margin-bottom: 10px;
}

.divDir {
  width: 100%;
  height: 1px;
  background-color: #f8f8f8;
}
.contentTit {
  line-height: 35px;
  font-size: 24px;
  color: #ff870f;
  margin: 15px 0;
}

.gameRes {
  display: flex;
}

.gameResLeft {
  width: 1119px;
  flex-shrink: 0;
  margin-right: 21px;
}

.gameResRight {
  flex-grow: 1;
  flex-shrink: 0;
  width: 0;
  border-radius: 8px;
  padding: 0 18px;
  box-shadow: 0 0 10px 0 rgba(103, 103, 103, 0.23);
}
.gameResRightSelect {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  border-radius: 4px;
}
.gameResItem {
  margin-bottom: 10px;
  padding: 15px 33px 15px 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(103, 103, 103, 0.23);
  cursor: pointer;
}
.gameResRightPrice {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gameResItemIn {
  display: flex;
  align-items: center;
}

.gameResItemInI {
  width: 232px;
  height: 108px;
  border-radius: 5px;
  margin-right: 21px;
}
.gameResItemInIO {
  width: 121px;
  height: 110px;
  border-radius: 5px;
  margin-right: 9px;
}

.gameResItemInfoO {
  width: 450px;
  overflow: hidden;
  margin-right: 56px;
}

.gameResItemInfoG {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 22px;
  font-weight: bold;
  color: #363636;
  line-height: 33px;
  margin-bottom: 4px;
}
.gameResItemInfoGO {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 17px;
  font-weight: bold;
  color: #363636;
  line-height: 25px;
  margin-bottom: 16px;
}

.gameResItemInfoE {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 20px;
  font-size: 13px;
  color: #363636;
  margin-bottom: 15px;
}
.gameResItemOtherA {
  display: flex;
  font-size: 13px;
  margin-bottom: 15px;
}
.gameResItemOtherAa {
  color: #237aff;
  margin-right: 24px;
}
.gameResItemOtherAb {
  color: #ff2121;
  margin-right: 24px;
}
.gameResItemOtherAc {
  color: #2c2c2c;
}

.gameResItemInfoT {
  display: flex;
}

.gameResItemInfoTI {
  height: 23px;
  line-height: 23px;
  padding: 0 16px;
  font-size: 13px;
  border-radius: 4px;
  color: #58595a;
  background-color: #e3e3e3;
}
.gameResItemOtherB {
  display: flex;
}
.gameResItemOtherBI {
  width: 59px;
  height: 24px;
  border: 1px solid #ff870f;
  line-height: 24px;
  text-align: center;
  font-size: 13px;
  border-radius: 2px;
  color: #ff870f;
  background-color: rgba(255, 135, 15, 0.19);
  margin-right: 13px;
}

.gameResItemInP {
  align-self: flex-start;
  width: 100%;
  height: 29px;
  line-height: 29px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 400;
  color: #363636;
}

.gameResItemInPw {
  width: 240px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.gameResItemInPrice {
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}

.gameResItemInPriceB {
  height: 26px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.gameResItemInPriceBzhw {
  font-size: 15px;
  font-weight: 400;
  color: #363636;
  line-height: 21px;
  margin-bottom: 11px;
}

.gameResItemInPriceI {
  width: 71px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  padding-right: 8px;
  font-size: 16px;
  color: #934c05;
  background-image: url("../../assets/jhbgImg.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 9px;
}

.gameResItemInPriceP {
  font-size: 28px;
  font-weight: bold;
  color: #ff3636;
}
.gameResItemInPricePO {
  font-size: 20px;
  font-weight: bold;
  color: #ff3636;
}
.gameResItemInPricePD {
  font-size: 23px;
}
.gameResItemInPricePDO {
  font-size: 16px;
}

.gameResItemInPriceBL {
  font-size: 18px;
  color: #e3e3e3;
  text-decoration: line-through;
  /* margin-left: 12px; */
  margin-right: 30px;
}

.gameResItemInPriceBR {
  font-size: 16px;
  color: #07b200;
  font-weight: bold;
}
.gameResItemInBtnW {
  flex-grow: 1;
  width: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.gameResItemInBtn {
  width: 108px;
  height: 31px;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 31px;
}
.gameResItemInBtnB {
  background-color: rgba(255, 135, 15, 1);
  margin-bottom: 10px;
}
.gameResItemInBtnB:hover {
  background-color: rgba(255, 135, 15, 0.7) !important;
}
.gameResItemInBtnI {
  width: 16px;
  height: 15px;
  margin-right: 7px;
}
.gameResItemInBtnV {
  color: #71400e;
  background-color: #ffffff !important;
  background-image: url("../../assets/vipBtnBgc.png");
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: none !important;
  border: none !important;
}
.gameResItemInBtnV:hover {
  color: #71400e !important;
  background-color: #ffffff !important;
}
.gameResItemInBtnS {
  background-color: rgba(255, 135, 15, 1);
}
.gameResItemInBtnS:hover {
  background-color: rgba(255, 135, 15, 0.7) !important;
}
</style>
<style>
.gameResRight .ant-collapse-borderless {
  background-color: #fff !important;
}

.gameResRight .ant-collapse-header {
  align-items: center !important;
  padding: 28px 0 21px !important;
  border-bottom: 2px solid #eaeaea;
}

.gameResRight .ant-collapse-header-text {
  font-size: 18px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.gameResRight .ant-collapse-header .ant-collapse-arrow {
  font-size: 16px !important;
}

.gameResRight .ant-collapse-content-box {
  padding: 0 !important;
  margin-top: 30px !important;
}

.gameResRight .ant-collapse-item {
  border-bottom: none !important;
}

.gameResRight .ant-radio-wrapper,
.gameResRight .ant-checkbox-wrapper {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  margin-bottom: 30px !important;
}
</style>
