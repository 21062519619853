<template>
  <div>
    <a-button @click="openModal">打开 Modal 弹窗</a-button>
    <a-button @click="showMessage">显示 Message 消息</a-button>

    <GlobalMessageModal
      :isOpen="isModalOpen"
      alterType="modal"
      :new_id="5"
      message="这是一个测试消息"
      level="warning"
      @setIsOpen="isModalOpen = $event"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import GlobalMessageModal from "@/components/GobalMessageModal.vue";

export default {
  components: {
    GlobalMessageModal,
  },
  setup() {
    const isModalOpen = ref(false);

    const openModal = () => {
      isModalOpen.value = true;
    };

    const showMessage = () => {
      isModalOpen.value = true; // 打开 message 模式
    };

    return {
      isModalOpen,
      openModal,
      showMessage,
    };
  },
};
</script>
