<!-- 购买cdk right -->
<template>
  <div :class="$style.topBuy" :id="type === 'game' ? 'buyCdk'  : 'mbuyCdk'">
    <!-- 游戏逻辑 -->
    <template v-if="type === 'game'">
      <div :class="$style.topBuyLab">选择游戏</div>
      <div :class="$style.topBuySelect">
        <a-select
          v-model:value="curGame"
          show-search
          style="width: 100%; height: 100%"
          placeholder="请选择游戏"
          :options="gameOptions"
          :filter-option="false"
          @search="handleSearchGame"
          @select="handleSelectGame"
        />
      </div>
      <div :class="$style.topBuyLab">选择SKU</div>
      <div :class="$style.topBuySeSku">
        <a-select
          v-model:value="curSku"
          style="width: 100%; height: 100%"
          placeholder="请选择SKU"
          :options="skuOptions"
          @select="handleSelectSku"
        />
      </div>
    </template>

    <!-- 会员逻辑 -->
    <template v-else-if="type === 'member'">
      <div :class="$style.topBuyLab">选择会员</div>
      <div :class="$style.topBuySelect">
        <a-select
          v-model:value="curMember"
          show-search
          style="width: 100%; height: 100%"
          placeholder="请选择会员"
          :options="memberOptions"
          :filter-option="false"
        />
      </div>
      <div :class="$style.topBuyLab">选择时长</div>
      <div :class="$style.topBuySeSku">
        <a-select
          v-model:value="curDuration"
          style="width: 100%; height: 100%"
          placeholder="请选择时长"
          :options="durationOptions"
          @select="handleSelectDuration"
        />
      </div>
    </template>

    <!-- 公共部分 -->
    <div :class="$style.topBuyFooter">
      <div :class="$style.topBuyFooterL">
        <div :class="$style.topBuyFooterLL">单价</div>
        <div :class="$style.topBuyFooterLI">
          <a-input-number v-model:value="unitPrice" style="width: 100%; height: 100%" />
        </div>
        <div :class="$style.topBuyFooterLL">数量</div>
        <div :class="$style.topBuyFooterLI">
          <a-input-number v-model:value="amount" style="width: 100%; height: 100%" :min="2" />
        </div>
        <div :class="$style.topBuyFooterLL">金额</div>
        <div :class="$style.topBuyFooterLI">
          <a-input-number v-model:value="moneyAmount" style="width: 100%; height: 100%" />
        </div>
        <div :class="$style.topBuyFooterLL">推荐售价</div>
        <div :class="$style.topBuyFooterLI">
          <a-input-number v-model:value="reMoney" style="width: 100%; height: 100%" :disabled="true" />
        </div>
      </div>
      <a-button :class="$style.topBuyFooterB" type="primary" @click="onClickBuy">确认购买</a-button>
    </div>
    <div :class="$style.gameTip">温馨提示：未开通的游戏请联系客服开通</div>
  </div>
  <div :class="$style.searchCdk">
    <div :class="$style.searchCdkI">
      <a-input-search
        v-model:value="curCDKey"
        placeholder="请输入CDKey"
        @search="onSearchCDKey"
      />
    </div>
    <div :class="$style.searchCdkI">
      <a-input-search
        v-model:value="curGameName"
        placeholder="请输入游戏名称"
        @search="onSearchGameName"
      />
    </div>
  </div>
  <div :id="type === 'game' ? 'unUseCdk'  : 'munUseCdk'">
    <DetailTable
      :leftIcon="zkjIcon"
      title="未使用"
      :columns="unUseColumns"
      :searchParams="params"
      :fetch-data="fetchUnUseData"
      :get-reload="getReloadUnUse"
    >
      <template #topOperations>
        <!-- <div :class="$style.topOperations">
          <a-input-number v-model:value="copyLineS" addon-after="行" :class="$style.topOperationsL"></a-input-number>
          ~
          <a-input-number v-model:value="copyLineE" addon-after="行" :class="$style.topOperationsL"></a-input-number>
        </div>
        <a-button :class="$style.topOperationsC" type="default" @click="onTopCopyCdk">
          CDK复制
        </a-button> -->
        <a-button :class="$style.topOperationsD" type="default" @click="onExportUnCdk">
          导出未使用CDK
        </a-button>
      </template>
      <template #operationBtn="{ record }">
        <a-button :loading="unUseBtnLoading" :class="[$style.operationBtn, $style.operationR]" type="primary" shape="round" @click="onRefundUnUse(record)">
          退款
        </a-button>
      </template>
      <template #cdkeyCopy="{ text }">
        <a-button :class="[$style.operationBtn, $style.operationC]" type="primary" shape="round" @click="onCopyCdk(text)">
          复制
        </a-button>
      </template>
    </DetailTable>
  </div>
  <div :id="type === 'game' ? 'usedCdk'  : 'musedCdk'">
    <DetailTable :get-reload="getReloadUsed" :searchParams="params" :leftIcon="usedIcon" title="已使用" :columns="usedColumns" :fetch-data="fetchUsedData"/>
  </div>
  <div :id="type === 'game' ? 'refundedCdk'  : 'mrefundedCdk'">
    <DetailTable :get-reload="getReloadRefunded" :searchParams="params" :leftIcon="zkjIcon" title="已退款" :columns="refundedColumns" :fetch-data="fetchRefundedData"/>
  </div>
  <!-- <DetailTable :leftIcon="gameTIcon" title="游戏分类" :columns="gameTColumns" :fetch-data="fetchGameTData"/> -->
  <PaymentModal
    :isOpen="isOpen"
    :isOrderOpen="isOrderOpen"
    :curSku="curSkuPaymentModal"
    :paymentType="paymentType"
    :orderInfo="orderInfo"
    @setIsOpen="(value) => (isOpen = value)"
    @setIsOrderOpen="(value) => (isOrderOpen = value)"
  />
</template>

<script setup>
  import { ref, computed, onMounted, watch } from "vue";
  import axios from "@/axios";
  import { useStore } from 'vuex';
  import useClipboard from 'vue-clipboard3';

  import { openLink } from "@/util/utils.js";
  import DetailTable from '@components/DetailTable.vue';
  import zkjIcon from "@assets/zkjIcon.png";
  import usedIcon from "@assets/usedIcon.png";
  import gameTIcon from "@assets/gameTIcon.png";
  import { message } from "ant-design-vue";

  import PaymentModal from "@/components/BuyPlanModalv3.vue";

  const props = defineProps({
    type: String, // tab type
  });

  const isOpen = ref(false);
  const isOrderOpen = ref(false);
  const paymentType = ref(0);
  const orderInfo = ref(null);
  const curSkuPaymentModal = ref(null);

  const { toClipboard } = useClipboard();
  const store = useStore();
  const curGame = ref(undefined);
  const curSku = ref(undefined);
  const gameOptions = ref([]);
  const skuOptions = ref([]);
  const unitPrice = ref(undefined);
  const amount = ref(undefined);
  const moneyAmount = ref(undefined);
  const curCDKey = ref(undefined);
  const curGameName = ref(undefined);
  const unUseBtnLoading = ref(false);
  const reMoney = ref(undefined);

  const originalGameOptions = ref([]); // 用于存储原始数据
  let reloadUnUse;
  let reloadUsed;
  let reloadRefunded;

  const getReloadUnUse = (run) => {
    reloadUnUse = run;
  };
  const getReloadUsed = (run) => {
    reloadUsed = run;
  };
  const getReloadRefunded = (run) => {
    reloadRefunded = run;
  };

  // 本地模糊搜索
  const handleSearchGame = (val) => {
    const searchValue = val.toLowerCase(); // 转为小写以实现大小写不敏感搜索
    gameOptions.value = originalGameOptions.value.filter((item) =>
      item.label.toLowerCase().includes(searchValue)
    );
  };

  // 当选择一个游戏时，清空 SKU 并加载对应的 SKU 数据
  const handleSelectGame = (val) => {
    curSku.value = undefined;
    fetSkuData(val);
  };

  const handleSelectSku = (val) => {
    const selectedSku = skuOptions.value.find((sku) => sku.value === val);
    console.log(selectedSku);
    if (selectedSku) {
      unitPrice.value = selectedSku.price; // 设置单价
      console.log(89898, selectedSku.tuijian_dingjia)
      reMoney.value = selectedSku.tuijian_dingjia
      amount.value = 2; // 默认购买数量为 2
      // moneyAmount.value = unitPrice.value * amount.value; // 计算总金额
    }
  };

  // 监听单价变化
  watch(unitPrice, (newVal) => {
    if (newVal && amount.value) {
      moneyAmount.value = newVal * amount.value;
    }
  });

  // 监听数量变化
  watch(amount, (newVal) => {
    if (newVal && unitPrice.value) {
      moneyAmount.value = Math.round(newVal * unitPrice.value * 100) / 100;
    }
  });

  // 获取游戏选择options
  let timeout;
  const fetchGameData = (values = {}, callback) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    async function fake() {
      const res = await axios.post("web/get_user_proxy_buy_cdk_game_enum/", {
        ...values,
      }).catch(() => {});
      if (res?.data?.status === 10000) {
        const options = res?.data?.data?.map?.(item => ({ value: item?.game_id, label: item?.game_name }));
        console.log(res?.data);
        callback(options);
      }
    }

    timeout = setTimeout(fake, 300);
  }

  // 获取游戏sku
  const fetSkuData = async (gameId) => {
    if (!gameId) return;
    const res = await axios.post("web/get_user_proxy_buy_cdk_sku_enum/", { game_id: gameId}).catch(() => {});
    if (res?.data?.status === 10000) {
      skuOptions.value = res?.data?.data?.map?.(item => ({ value: item?.id, label: item?.sku_name, price: item?.cdk_price, tuijian_dingjia: item?.tuijian_dingjia }))
    }
  }

  // 定义会员选项和时长选项
  const memberOptions = ref([
    { label: "高级会员", value: 3 } // 只有高级会员
  ]);

  const durationOptions = ref([]); // 时长选项

  // 当前选择的会员和时长
  const curMember = ref(3); // 默认选择高级会员
  const curDuration = ref(undefined);

  // 从接口获取时长信息
  const fetchDurationOptionsFromAPI = async () => {
    try {
      const res = await axios.post("web/get_members_buy_plan/");
      if (res?.data?.status === 10000 && Array.isArray(res?.data?.data)) {
        // 将接口返回的数据映射到时长选项
        durationOptions.value = res.data.data.map((item) => ({
          label: item.time_remark, // 时长名称，如 "一月"、"三月"、"一年"
          value: item.time_remark, // 唯一标识，使用 time_remark
          price: item.price, // 原价
          cdk_price: item.cdk_price // CDK 价格
        }));
      } else {
        message.error("获取时长选项失败");
      }
    } catch (error) {
      console.error("获取时长信息失败", error);
      message.error("获取时长信息失败");
    }
  };

  // 处理选择时长
  const handleSelectDuration = (val) => {
    const selectedDuration = durationOptions.value.find((duration) => duration.value === val);
    if (selectedDuration) {
      unitPrice.value = selectedDuration.cdk_price; // 设置单价为 CDK 价格
      amount.value = 2; // 默认购买数量为 2
    }
  };

  // 页面挂载时初始化游戏选项
  onMounted(() => {
    fetchGameData({}, (data) => {
      gameOptions.value = data; // 初始化当前选项
      originalGameOptions.value = data; // 初始化原始数据
    });
    fetchDurationOptionsFromAPI();
  });

  // 确认购买
  const onClickBuy = async () => {
    if (props.type === "member") {
      if (!curDuration.value) {
        message.error("请选择时长");
        return;
      }
      console.log(`购买会员：${curMember.value}，时长：${curDuration.value}`);
      await axios.post("web/proxy_generate_cdk/", {
        vipid: curMember.value,
        vip_sku_value: curDuration.value,
        total_price: moneyAmount.value,
        num: amount.value,
      })
    } else {
      if (!curGame.value || !curSku.value) {
        message.error("请选择游戏和SKU");
        return;
      }
      console.log(`购买游戏：${curGame.value}，SKU：${curSku.value}`);
      await axios.post("web/proxy_generate_cdk/", {
        skuid: curSku.value,
        total_price: moneyAmount.value,
        num: amount.value,
      })
    }
    store.dispatch('fetchUserInfo');
    fetchAllData();
  };

  // 搜索和分页参数统一管理
  const params = ref({
    cdknb: "", // CDKey搜索字段
    gname: "", // 游戏名称搜索字段
    page: 1,   // 当前页
    limit: 5,  // 每页条数
  });

  // 未使用CDK表头
  const unUseColumns = [
    { title: 'CDKey', dataIndex: 'cknb' },
    { title: '类型', dataIndex: 'morg_display' },
    { title: '会员等级/游戏名称', dataIndex: 'game', width: '11%' },
    { title: '时长', dataIndex: 'otime', width: '8%' },
    { title: '进货价', dataIndex: 'prics', width: '10%' },
    { title: 'SKU销售价', dataIndex: 'sku_price', width: '12%' },
    { title: '推荐定价', dataIndex: 'recommended_price', width: '12%'  },
    { title: '创建时间', dataIndex: 'created' },
    { title: '退款', dataIndex: 'operation', width: '10%' },
  ];

  // 已使用CDK表头
  const usedColumns = [
    { title: 'CDKey', dataIndex: 'cknb' },
    { title: '类型', dataIndex: 'morg_display' },
    { title: '会员等级/游戏名称', dataIndex: 'game' },
    { title: '时长', dataIndex: 'otime' },
    { title: '使用者', dataIndex: 'use_user_name' },
    { title: '使用时间', dataIndex: 'used_time' },
  ];

  // 已退款CDK表头
  const refundedColumns = [
    { title: 'CDKey', dataIndex: 'cknb' },
    { title: '类型', dataIndex: 'morg_display' },
    { title: '会员等级/游戏名称', dataIndex: 'game' },
    { title: '时长', dataIndex: 'otime' },
  ];

  // 获取未使用CDK数据
  const fetchUnUseData = () => {
    return axios.post('web/belong_user_proxy_cdkeys/', {
      ...params.value,
      used: 0, // 未使用状态
    });
  };

  // 获取已使用CDK数据
  const fetchUsedData = () => {
    return axios.post('web/belong_user_proxy_cdkeys/', {
      ...params.value,
      used: 1, // 已使用状态
    });
  };

  // 获取已退款CDK数据
  const fetchRefundedData = () => {
    return axios.post('web/belong_user_proxy_cdkeys/', {
      ...params.value,
      used: 2, // 已退款状态
    });
  };

  // 搜索CDKey
  const onSearchCDKey = (CDKey) => {
    if (!CDKey) {
      message.error("请输入CDKey");
      return;
    }
    params.value.cdknb = CDKey; // 更新CDKey搜索条件
    params.value.page = 1; // 重置分页到第一页
    fetchAllData(); // 同时请求三个表格的数据
  };

  // 搜索游戏名称
  const onSearchGameName = (gameName) => {
    if (!gameName) {
      message.error("请输入游戏名称");
      return;
    }
    params.value.gname = gameName; // 更新游戏名称搜索条件
    params.value.page = 1; // 重置分页到第一页
    fetchAllData(); // 同时请求三个表格的数据
  };

  // 分页切换处理
  const onPageChange = (page, pageSize) => {
    params.value.page = page; // 更新当前页
    params.value.limit = pageSize; // 更新每页条数
    fetchAllData(); // 同时请求三个表格的数据
  };

  // 同时请求三个表格的数据
  const fetchAllData = () => {
    reloadUnUse?.();
    reloadUsed?.();
    reloadRefunded?.();
  };

  // 未使用退款操作
  const onRefundUnUse = async (record) => {
    unUseBtnLoading.value = true;
    await axios.post("web/proxy_cdkey_hs/", { nid: record?.id }).catch(() => {});
    unUseBtnLoading.value = false;
    reloadUnUse?.();
    store.dispatch('fetchUserInfo');
  }

  // 表格上方cdk复制操作
  const onTopCopyCdk = async (text) => {
    if (!text) return;
    try {
      await toClipboard(text);
      message.success('复制成功');
    } catch (e) {
      console.error(e)
    }
  }

  // 复制cdk
  const onCopyCdk = async (text) => {
    if (!text) return;
    try {
      await toClipboard(text);
      message.success('复制成功');
    } catch (e) {
      console.error(e)
    }
  }

  const onExportUnCdk = async () => {
  try {
    // 发起 POST 请求，指定 responseType 为 blob
    const res = await axios.post("web/proxy_unuser_cdk_download/", null, {
      responseType: 'blob', // 告诉 axios 返回的是二进制数据
    });

    // 检查响应状态
    if (res?.status === 200) {
      // 从响应头中解析文件名（如果后端提供了文件名）
      const disposition = res.headers['content-disposition'];
      let fileName = 'exported_file.xlsx'; // 默认文件名
      if (disposition) {
        const fileNameMatch = disposition.match(/filename\*=UTF-8''(.+)/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = decodeURIComponent(fileNameMatch[1]);
        } else {
          const fallbackMatch = disposition.match(/filename="(.+)"/);
          if (fallbackMatch && fallbackMatch[1]) {
            fileName = fallbackMatch[1];
          }
        }
      }

      // 创建 Blob 对象并生成下载链接
      const blob = new Blob([res.data], { type: res.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); // 设置下载文件名
      document.body.appendChild(link);
      link.click(); // 触发下载
      link.remove(); // 移除临时链接
      window.URL.revokeObjectURL(url); // 释放 URL 对象
    } else {
      console.error('导出失败', res);
    }
  } catch (error) {
    console.error('下载文件失败', error);
  }
};


  // 游戏分类 columns
  // const gameTColumns = [
  //   { title: '事件', dataIndex: 'type' },
  //   { title: '抵扣金额', dataIndex: 'amount' },
  //   { title: '抵扣前金额', dataIndex: 'pre_amount' },
  //   { title: '抵扣后金额', dataIndex: 'after_amount' },
  //   { title: '时间', dataIndex: 'created' },
  // ];
  // const fetchGameTData = (params) => {
  //   return axios.post('web/user_dkj_logs/', { limit: params?.limit || 5, page: params?.page || 1 });
  // };
</script>

<style module>
  .gameTip {
    margin-top: 20px;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    color: #E81123;
  }
  .topBuy {
    border-radius: 5px;
    border: 1px solid #EAEAEA;
    padding: 20px 28px 26px 23px;
    margin-bottom: 32px;
  }
  .topBuyLab {
    font-size: 18px;
    color: #333333;
    line-height: 26px;
    margin-bottom: 16px;
  }
  .topBuySelect {
    width: 572px;
    height: 40px;
    margin-bottom: 24px;
  }
  .topBuySeSku {
    width: 260px;
    height: 40px;
    margin-bottom: 28px;
  }
  .topBuyDir {
    width: 572px;
    height: 1px;
    background-color: #D9D9D9;
    margin-bottom: 25px;
  }
  .topBuyFooter {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .topBuyFooterL {
    display: flex;
    align-items: center;
  }
  .topBuyFooterLL {
    font-size: 18px;
    color: #333333;
    margin-right: 28px;
  }
  .topBuyFooterLI {
    width: 100px;
    height: 40px;
    margin-right: 40px;
  }
  .topBuyFooterB {
    width: 206px;
    height: 56px;
    font-size: 24px;
    font-weight: bold;
  }
  .searchCdk {
    display: flex;
    margin-bottom: 24px;
  }
  .searchCdkI {
    width: 426px;
    height: 50px;
    margin-right: 82px;
  }
  .operationBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 35px;
    font-size: 16px;
  }
  .operationR {
    background-color: rgba(255, 135, 15, 1);
  }
  .operationR:hover {
    background-color: rgba(255, 135, 15, .7) !important;
  }
  .operationC {
    background-color: rgba(35, 122, 255, 1);
  }
  .operationC:hover {
    background-color: rgba(35, 122, 255, .7) !important;
  }
  .topOperations {
    margin-right: 48px;
  }
  .topOperationsL {
    width: 130px;
  }
  .topOperationsC {
    margin-right: 26px;
  }
</style>
<style>
  .buycdk-right .ant-tabs-nav::before {
    border-bottom: none !important;
  }
  .buycdk-right .ant-input-number {
    font-size: 18px !important;
  }
  .buycdk-right .ant-input-number-input {
    height: 40px !important;
  }
  .buycdk-right .ant-select-selector {
    height: 100% !important;
    font-size: 18px !important;
    /* line-height: 40px !important; */
  }
  .buycdk-right .ant-select-selection-search-input {
    height: 40px !important;
  }
  .buycdk-right .ant-select-selection-placeholder{
    line-height: 40px !important;
  }
  .buycdk-right .ant-select-selection-item{
    line-height: 40px !important;
  }
  .buycdk-right .ant-input {
    height: 50px !important;
    font-size: 18px !important;
  }
  .buycdk-right .ant-input-search-button {
    height: 50px !important;
    width: 40px !important;
    font-size: 20px !important;
  }
</style>
