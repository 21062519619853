<template>
  <div>
    <div :class="$style.header">
      <div :class="$style.left">
        <img :class="$style.leftIcon" :src="leftIcon" alt="" />
        <div>{{leftTitle || '-'}}</div>
      </div>
      <div :class="$style.right" @click="onClickMore">
        <div>更多</div>
        <img :class="$style.rightIcon" :src="rightIcon" alt="" />
      </div>
    </div>
    <div :class="$style.carouselWrap">
      <a-carousel
        :dots="false"
        arrows
        v-if="gameInfos?.length > 0"
      >
        <template #prevArrow>
          <div :class="[$style.customArrow, $style.leftArrow]">
            <img :class="$style.leftArrowImg" :src="leftImg" alt="" />
          </div>
        </template>
        <template #nextArrow>
          <div :class="[$style.customArrow, $style.rightArrow]">
            <img :class="$style.rightArrowImg" :src="rightImg" alt="" />
          </div>
        </template>
        <div v-for="item in gameInfos" :class="$style.bannerCard">
          <div
            v-for="cur in item"
            :class="$style.cardGrid"
            @click="onClickGame(cur)"
          >
            <img
              :src="getFullImageUrl(cur?.img)"
              :class="$style.cardGridImg"
              alt=""
            />
            <div :class="$style.cardGridCon">
              <div :class="$style.cardGridTitle">{{cur?.gname}}</div>
              <div :class="$style.cardGridTitleE">{{cur?.ename}}</div>
              <div :class="$style.cardGridMoney">¥{{cur?.sku_price_info?.show_price_info?.use_dkj_price}}</div>
            </div>
          </div>
        </div>
      </a-carousel>
    </div>
  </div>
</template>
<script setup>
  import { useRouter } from 'vue-router'
  import { getFullImageUrl } from "@/util/utils.js";
  import rightIcon from "@assets/rightIcon.png";

  const props = defineProps({
    leftIcon: String, // 头部左侧图标
    leftTitle: String, // 头部标题
    leftImg: String, // banner左侧图标
    rightImg: String, // banner右侧图标
    gameInfos: Array, // 展示的游戏信息列表
  });

  const emit = defineEmits(["onClickMore"]);
  const router = useRouter();

  // 点击更多操作
  const onClickMore = () => {
    emit("onClickMore");
  };
  
  // 点击游戏图片
  const onClickGame = (current) => {
    router.push(`/detail/${current?.id || undefined}`);
  }
</script>
<style module>
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .left {
    display: flex;
    align-items: center;
    font-size: 28px;
    color: #333333;
    font-weight: bold;
  }
  .leftIcon {
    width: 35px;
    height: 30px;
    margin-right: 16px;
  }
  .right {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #3B3B3B;
    cursor: pointer;
  }
  .rightIcon {
    width: 13px;
    margin-left: 14px;
  }
  .customArrow {
    width: 39px !important;
    height: 39px !important;
    /* bottom: -52px !important; */
    /* top: 50% !important; */
    /* left: 50% !important; */
  }
  .leftArrow {
    transform: translate(-23px);
  }
  .rightArrow {
    transform: translate(23px);
  }
  .leftArrowImg {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .rightArrowImg {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .carouselWrap {
    width: 92%;
    margin: 0 auto;
  }
  .bannerCard {
    display: flex !important;
  }
  .cardGrid {
    width: 330px;
    height: 167px;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
  }
  .cardGrid:last-child {
    margin-right: 0;
  }
  .cardGridImg {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
  .cardGridCon {
    position: absolute;
    left: 17px;
    bottom: 13px;
  }
  .cardGridTitle {
    font-size: 18px;
    line-height: 18px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 8px;
    text-shadow: 0px 0px 2px black, 0px 0px 2px black, 0px 0px 2px black, 0px 0px 2px black; /* 黑色描边 */
  }

  .cardGridTitleE {
    font-size: 11px;
    line-height: 11px;
    color: #fff;
    margin-bottom: 8px;
    text-shadow: 0px 0px 2px black, 0px 0px 2px black, 0px 0px 2px black, 0px 0px 2px black; /* 黑色描边 */
  }

  .cardGridMoney {
    font-size: 15px;
    line-height: 15px;
    color: #FFE202;
    font-weight: bold;
    text-shadow: 0px 0px 2px black, 0px 0px 2px black, 0px 0px 2px black, 0px 0px 2px black; /* 黑色描边 */
  }

</style>